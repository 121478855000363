import centers from '@/constants/centers.ts';

export default function (syndications) {
    const { currentCenter } = useCenters();

    if (!syndications) {
        return null;
    }

    const blog = computed(() => {
        return syndications?.blogs?.[0]?.reference || syndications?.blogs?.[0];
    });
    if (blog.value) {
        let blogIcon = '';

        if (blog.value.path.includes('politika')) {
            blogIcon = 'politika';
        }

        if (blog.value.path.includes('strategic')) {
            blogIcon = 'strategiceurope';
        }

        if (blog.value.path.includes('sada')) {
            blogIcon = 'sada';
        }

        if (blog.value.path.includes('financial')) {
            blogIcon = 'cfm';
        }

        if (blog.value.path.includes('diwan')) {
            blogIcon = 'diwan';
        }

        if (blog.value.path.includes('emissary')) {
            blogIcon = 'emissary';
        }
        return {
            attributionType: 'blog',
            attributionIcon: blogIcon,
            attributionItems: [
                {
                    title: blog.value.title || '',
                    path: blog.value.path || null,
                    heroImage: blog.value.heroBlog?.heroImage,
                    logo: blog.value.heroBlog?.logo,
                },
            ],
        };
    } else {
        const centerAttributions = computed(() => {
            const currentCenterSlug = currentCenter.value.slug;

            return centers.filter((center) => {
                if (
                    syndications?.centers[center.id]?.syndicated &&
                    syndications?.centers[center.id]?.isPrimary &&
                    center.id !== 'global' &&
                    center.id !== currentCenterSlug
                ) {
                    return {
                        title: center.title,
                        path: center.path,
                    };
                } else return null;
            });
        });

        return {
            attributionType: 'center',
            attributionIcon: 'center',
            attributionItems: centerAttributions.value,
        };
    }
}
